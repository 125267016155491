#step5{
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		width: 789px;
		bottom: -140px;
		right: 109px;
		z-index: 3;
	}

	.trophy {
		position: absolute;
		width: 700px;
		top: 162px;
		left: 150px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		bottom: 0px;
		left: 0;
		right: 0;
	}

	.content{
		position: absolute;
		left: 51%;
		top: 200px;
		width: 850px;
		z-index: 3;
	}
}
