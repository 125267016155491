#step9{
	.content{
		position: absolute;
		left:50%;
		top: 270px;
		z-index: 3;
		transform: translateX(-50%);
	}

	.eliza {
		position: absolute;
		width: 529px;
		bottom: 250px;
		right: 220px;
		z-index: 3;
	}

	.eliza-sad {
		position: absolute;
		width: 1055px;
		top: 340px;
		left: -114px;
		z-index: 2;
	}

	.elements{
		position: absolute;
		max-width: none;
		max-height: none;
		width: 150px;
		top: 40px;
		left: 240px;
	}

	.curve{
		position: absolute;
		bottom: -400px;
		left: -70%;
		width: 200%;
		transform: rotate(-25deg);
	}

	.message{
		position: absolute;
		top: 40px;
		left: 240px;
	}

	.hands {
		position: absolute;
		width: 350px;
		top: 355px;
		left: 200px;
		transform: scaleX(-100%);
	}

	.animation {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		transform: translate3d(0,-35%,0);
	}
}
