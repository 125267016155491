#step15 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.puzzle {
		position: absolute;
		width: 900px;
		top: 230px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 3;
	}

	.shape {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
	}

	.eliza {
		position: absolute;
		width: 929px;
		bottom: -50px;
		right: -100px;
		z-index: 1;
	}

	.logo {
		position: absolute;
		width: 450px;
		top: 205px;
		left: -280px;
		right: 0;
		margin: 0 auto;
		z-index: 2;
	}

	.buttons {
		position: absolute;
		left: 0px;
		right: 280px;
		top: 475px;
		margin: 0 auto;
	}

	.game {
		position: absolute;
		top: 213px;
		width: 1280px;
		height: 735px;
		left: 0px;
		right: 7px;
		margin: 0 auto;
	}

	.monitor {
		position: absolute;
		height: 740px;
		bottom: 300px;
		left: 0px;
		right: 350px;
		margin: 0 auto;
	}

	.monitor-game {
		position: absolute;
		height: 1050px;
		bottom: 30px;
		left: 0px;
		right: 80px;
		margin: 0 auto;
	}
}
