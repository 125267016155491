#step17 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.title {
		position: absolute;
		top: 200px;
		left: 0;
		right: 0;
	}

	.selo {
		position: absolute;
		top: 400px;
		left: 0;
		right: 0;
	}

	.selo-image {
		position: absolute;
		top: 250px;
		left: 0;
		right: 750px;
		width: 700px;
		margin: 0 auto;
		z-index: 2;
	}

	.eliza {
		position: absolute;
		top: 350px;
		left: 30px;
		width: 550px;
		z-index: 2;
	}

	.eliza-happy {
		position: absolute;
		top: 250px;
		right: 100px;
		width: 900px;
		z-index: 2;
	}

	.eliza-sad {
		position: absolute;
		top: 300px;
		left: -100px;
		width: 1100px;
		z-index: 2;
	}

	.text {
		position: absolute;
		top: 150px;
		width: 50%;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
		z-index: 2;
	}

	.shape {
		position: absolute;
		left: 0px;
		bottom: 0px;
		right: 0px;
		z-index: 1;
	}

	.hands {
		position: absolute;
		width: 300px;
		top: 275px;
		left: 200px;
		transform: scaleX(-100%);
	}

	.selo-down {
		position: absolute;
		animation: slideDown 2s ease-in-out forwards;
		z-index: 3;
		position: absolute;
		top: 450px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.animation {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		transform: translate3d(0, -35%, 0);
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(135%) rotate(374deg);
	}
}
