#step3 {
	position: relative;
	width: 100%;
	height: 100%;

	.eliza {
		position: absolute;
		width: 789px;
		bottom: 298px;
		right: 209px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		top: 5px;
		left: -35px;
		transform: rotate(194deg);
		width: 2230px;
		height: 1200px;
		object-fit: contain;
		max-width: none;
		max-height: none;
	}

	.anuario {
		position: absolute;
		width: 980px;
		height: 980px;
		border-radius: 1000px;
		top: -80px;
		left: 90px;
		z-index: 1;
		overflow: hidden;
	}

	.qrcode {
		position: absolute;
		width: 380px;
		height: 380px;

		top: 0px;
		right: 250px;
		z-index: 1;
		overflow: hidden;

		> div {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			overflow: hidden;
			border-radius: 340px;
			background: #302d51;
			padding: 40px;
			img {
				position: relative;
				width: 100%;
				height: 100%;
			}
		}
	}
}
