#step10 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		width: 600px;
		left: 690px;
		top: 200px;
	}

	.gestao,
	.governanca {
		display: flex;
		flex-flow: column;
		align-items: center;
		text-align: center;

		.progress {
			transform: scaleY(0);
			transform-origin: bottom;
		}
	}

	.gestao {
		position: absolute;
		top: 250px;
		right: 200px;

		.progress {
			animation: fillUp 0.6s ease-in-out 0.8s forwards;
		}
	}

	.governanca {
		position: absolute;
		top: 250px;
		left: 200px;

		.progress {
			animation: fillUp 0.9s ease-in-out 0.5s forwards;
		}
	}

	.title {
		position: absolute;
		left: 0;
		right: 0;
		top: 80px;
		margin: 0 auto;
		width: 50%;
		text-align: center;
	}
}

#step10-identidade {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		width: 600px;
		left: 690px;
		top: 200px;
	}

	.gestao,
	.governanca {
		display: flex;
		flex-flow: column;
		align-items: center;
		text-align: center;

		.progress {
			transform: scaleY(0);
			transform-origin: bottom;
		}
	}

	.gestao {
		position: absolute;
		top: 250px;
		right: 200px;

		.progress {
			animation: fillUp 0.6s ease-in-out 0.8s forwards;
		}
	}

	.governanca {
		position: absolute;
		top: 250px;
		left: 200px;

		.progress {
			animation: fillUp 0.9s ease-in-out 0.5s forwards;
		}
	}

	.title {
		position: absolute;
		left: 0;
		right: 0;
		top: 80px;
		margin: 0 auto;
		width: 50%;
		text-align: center;
	}
}

@keyframes fillUp {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}
