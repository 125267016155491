#step18 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		z-index: 2;
		width: 400px;
		left: 320px;
		top: 60px;
		transform: scaleX(-100%);
	}

	.logo {
		position: absolute;
		width: 250px;
		top: 150px;
		left: 390px;
		z-index: 2;
		padding: 5px;

		img {
			width: 100%;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			width: 350px;
			height: 350px;
			background-color: #fff;
			border-radius: 1000px;
			z-index: -1;
		}
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: -18px;
		left: 209px;
		z-index: 1;
	}

	.resultados {
		position: absolute;
		right: 170px;
		top: 160px;
		z-index: 2;
		width: 700px;
		&::before {
			content: "";
			position: absolute;
			width: 1000px;
			height: 1000px;
			background-color: #fff;
			z-index: -1;
			top: -200px;
			left: -150px;
			border-radius: 800px;
		}
	}

	.text {
		position: absolute;
		left: 150px;
		bottom: 250px;
		width: 800px;
	}

	.text-2 {
		position: absolute;
		left: 260px;
		top: 420px;
		width: 500px;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		img {
			width: 60%;
		}
	}
}
