#step4 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		width: 929px;
		bottom: -50px;
		right: -150px;
		z-index: 1;
	}

	.monitor {
		position: absolute;
		height: 740px;
		bottom: 300px;
		left: 0px;
		right: 350px;
		margin: 0 auto;
	}

	.monitor-game {
		position: absolute;
		height: 1050px;
		bottom: 30px;
		left: 0px;
		right: 80px;
		margin: 0 auto;
	}

	.curve {
		position: absolute;
		bottom: 0px;
		transform: rotate(18deg);
	}

	.logo {
		position: absolute;
		width: 700px;
		top: 240px;
		left: -280px;
		right: 0;
		margin: 0 auto;
		z-index: 2;
	}

	.buttons {
		position: absolute;
		left: 0px;
		right: 280px;
		top: 475px;
		margin: 0 auto;
	}

	.game {
		position: absolute;
		top: 213px;
		width: 1280px;
		height: 735px;
		left: 0px;
		right: 7px;
		margin: 0 auto;
	}
}
