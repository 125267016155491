#step7 {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.eliza {
		position: absolute;
		width: 389px;
		bottom: -140px;
		left: 150px;
		z-index: 3;
	}

	.monitor {
		position: absolute;
		height: 990px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		margin: 0 auto;
	}

	.curve {
		position: absolute;
		bottom: -100px;
		transform: rotate(18deg);
	}

	.logo {
		position: absolute;
		width: 700px;
		top: 450px;
		left: 40px;
		right: 0;
		margin: 0 auto;
		z-index: 2;
	}

	.content {
		position: absolute;
		left: 500px;
		top: 270px;
		width: 970px;
		z-index: 3;

		img {
			position: absolute;
			max-width: none;
			max-height: none;
			width: 100% !important;
			height: 100% !important;
			object-fit: cover;
		}
	}

	.header {
		position: absolute;
		top: 140px;
		left: 830px;
		transform: translate3d(-50%, 0, 0);
		z-index: 3;
	}
	.content {
		img {
			width: 1300px;
			height: 600px;
			object-fit: contain;
			position: relative;
			top: -50px;
		}
	}
}
