#step2 {
	position: relative;
	width: 100%;
	height: 100%;

	.eliza {
		position: absolute;
		width: 489px;
		top: 138px;
		left: 369px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: 475px;
		left: -565px;
		transform: rotate(38deg);
	}

	.hands {
		position: absolute;
		width: 400px;
		top: 275px;
		right: 200px;
	}

	.curve {
		position: absolute;
		left: -50px;
		top: -20px;
		width: 105%;
		path {
			stroke-dasharray: 3000 3000;
			stroke-dashoffset: 3200;
			// transition: 5s linear;
			animation: fillPath 2s ease-in-out forwards;
		}
	}

	.fundacao,
	.media,
	.atual {
		position: absolute;
	}

	.fundacao {
		top: 360px;
		left: 150px;
		text-align: center;
		transform: scale(0);
		animation: zoomIn 0.3s ease-out 1s forwards;
	}

	.media,
	.atual {
		width: 350px;
		// border: 1px solid #fff;
	}

	.media {
		top: 435px;
		left: 900px;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
		transform: scale(0);
		animation: zoomIn 0.3s ease-out 1.2s forwards;
	}

	.atual {
		top: 495px;
		left: 1400px;
		text-align: center;
		display: flex;
		flex-flow: column;
		align-items: center;
		transform: scale(0);
		animation: zoomIn 0.3s ease-out 1.4s forwards;
	}

	.emoji {
		position: absolute;
		top: 170px;
		right: 400px;
		width: 300px;
	}
}

@keyframes fillPath {
	0% {
		stroke-dashoffset: 3200;
	}
	100% {
		stroke-dashoffset: 6000;
	}
}

@keyframes zoomIn {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
